<template>
  <div>
    <table class="w-100">
      <tbody v-for="(team, idx) in teams" :key="'team' + team.id + '_' + idx">
        <tr class="report-heading">
          <th>{{ idx + 1 }}. {{ team.name }}</th>
          <th v-if="round" class="text-right">{{ team.total || 'NTS' }}</th>
          <template v-else>
            <th v-for="round in rounds" :key="'round' + round" class="text-right">
              Rd.{{ round }}
              <br>
              {{ report.teams.find(t => t.teamId === team.id && t.round === round)
                ? report.teams.find(t => t.teamId === team.id && t.round === round).total
                : '-' }}
            </th>
            <th class="text-right">
              Total
              <br>
              {{ teamTotal(team.id) }}
            </th>
          </template>
        </tr>
        <tr v-for="(player, playerIdx) in teamPlayers(team.id)" :key="'player' + player.playerId + '_' + playerIdx"
          :class="[!player.isTeamPlayer && 'text-primary', (player.dropped || !player.total) && 'text-danger']">
          <td>
            <div class="d-inline-block" style="width: 2.5em">
              {{ player.place }}{{ tieMarker(player.place) }}
            </div>
            {{ player.firstName }} {{ player.lastName }} {{ !player.isTeamPlayer ? '(Ind.)' : '' }}
          </td>
          <td v-if="round" class="text-right">
            <div :style="{ 'text-decoration': player.isTeamPlayer && !player.counted  && 'line-through' }">
              {{ player.total || player.dropped }}
            </div>
          </td>
          <template v-else>
            <td v-for="(round, idx) in rounds" :key="'multiscore' + player.playerId + '-' + round"
              class="text-right">
              <div :style="{ 'text-decoration': player.isTeamPlayer && player.totals[idx] && !player.totals[idx].counted  && 'line-through' }">
                {{ player.totals[idx] ? (player.totals[idx].total || player.totals[idx].dropped) : '-' }}
              </div>
            </td>
            <td class="text-right">{{ player.grandTotal }}</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { sortZeroLast } from '@/utils/sort-utils'

export default {
  name: 'match-results-list',
  props: ['report', 'round'],
  computed: {
    districtChampionship () {
      const lower = this.report.eventName.toLowerCase()
      return lower && lower.indexOf('district') > -1 && lower.indexOf('championship') > -1
    },
    rounds () {
      return this.districtChampionship ? 1 : this.report.rounds
    },
    teams () {
      return this.report.teams.filter(t => t.round === this.round || (t.round === 1 && !this.round))
        .sort((a, b) => this.round ? a.place - b.place : this.teamTotal(a.id) - this.teamTotal(b.id))
    },
    teamTotal () {
      return teamId => {
        return this.report.teams.filter(t => t.round <= this.rounds && t.teamId === teamId).map(t => t.total).reduce((a, b) => a + b)
      }
    },
    playerScores () {
      return player => {
        const scores = this.report.players
          .filter(p => p.playerId === player.playerId)
        scores.sort((a, b) => a.round - b.round)
        return scores.map(p => ({ round: p.round, total: p.total, dropped: p.dropped, counted: p.counted }))
      }
    },
    players () {
      const players = this.report.players
        .filter(p => p.isTeamPlayer && (p.round === this.round || (!this.round && p.round === 1)))
        .map(p => ({
          ...p,
          totals: this.playerScores(p),
          grandTotal: this.playerScores(p).filter(s => s.round <= this.rounds).map(t => t.total).reduce((a, b) => a + b)
        }))

      players.forEach(p => {
        if (p.dropped || this.report.players.find(pp => pp.playerId === p.playerId && pp.dropped)) {
          p.grandTotal = 0
        }
      })
      players.sort((a, b) => this.round ? sortZeroLast(a, b, 'total') : sortZeroLast(a, b, 'grandTotal'))

      let lastScore = 0
      let lastPlace = 0
      for (let i = 0; i < players.length; i++) {
        const tot = this.round ? players[i].total : players[i].grandTotal
        if (i && tot === lastScore) {
          players[i].place = lastPlace
        } else {
          players[i].place = i + 1
          lastPlace = players[i].place
        }
        lastScore = tot
      }
      return players
    },
    teamPlayers () {
      return teamId => {
        return this.players.filter(p => p.teamId === teamId)
      }
    },
    tieMarker () {
      return rank => this.players.filter(p => p.place === rank).length > 1 ? '-T' : ''
    }
  }
}
</script>

<style>

</style>
