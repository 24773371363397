
export const sortZeroLast = (a, b, field, field2, dir = 1) => {
  if (a[field] === b[field] && field2) {
    return dir === -1 ? b[field2] - a[field2] : a[field2] - b[field2]
  }
  return a[field] && b[field]
    ? (dir === -1 ? b[field] - a[field] : a[field] - b[field])
    : a[field]
      ? -dir
      : dir
}
