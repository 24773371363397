<template>
  <div>
    <div class="report-heading">
      <div v-if="report.regionType === 99"
        style="float:right">
        <label :title="!individualPlayers.length ? 'There are no individual players in this tournament' : 'Show only individual players'">
          <input type="checkbox" v-model="onlyIndividuals" :disabled="!individualPlayers.length">
          Individuals Only
        </label>
      </div>
      <h5>Player Results</h5>
    </div>
    <table class="w-100">
      <tr class="table-row heading">
        <th>&nbsp;</th>
        <th @click="sortBy('lastName', '')">
          Player
          <sort-icon :sortObj="sortObj" field="lastName"></sort-icon>
        </th>
        <th @click="sortBy('teamCode', 'grantTotal')">
          Team
          <sort-icon :sortObj="sortObj" field="teamCode"></sort-icon>
        </th>
        <th v-if="round" @click="sortBy('total', 'lastName')" class="text-right">
          Score
          <sort-icon :sortObj="sortObj" field="total"></sort-icon>
        </th>
        <template v-else>
          <th v-for="rd in report.rounds" :key="'multihead' + rd" class="text-right">Rd.{{ rd }}</th>
          <th class="text-right" @click="sortBy('grandTotal')">
            Total
            <sort-icon :sortObj="sortObj" field="grandTotal"></sort-icon>
          </th>
        </template>
      </tr>
      <tr v-for="player in sortedData(visiblePlayers)" :key="'player' + player.playerId"
        :style="{ display: onlyIndividuals && !player.isIndividual ? 'none' : 'table-row' }"
        :class="[!player.isTeamPlayer && 'text-primary', (player.dropped || !player.total) && 'text-danger']">
        <td>
          {{ player.place }}
        </td>
        <td>
          {{ player.lastName }}, {{ player.firstName }} {{ player.isIndividual ? '(Ind.)' : '' }}
        </td>
        <td>
          {{ player.teamCode }}
        </td>
        <td v-if="round" :style="{ textDecoration: player.isTeamPlayer && !player.counted ? 'line-through' : 'none' }"
          class="text-right">
          {{ player.total || player.dropped }}
        </td>
        <template v-else>
          <td v-for="(rd, idx) in report.rounds" :key="'multiscore' + player.playerId + '-' + idx"
            class="text-right">
            <div :style="{ 'text-decoration': player.isTeamPlayer && player.totals[idx] && !player.totals[idx].counted  && 'line-through' }">
              {{ player.totals[idx] ? (player.totals[idx].total || player.totals[idx].dropped) : '-' }}
            </div>
          </td>
          <td class="text-right">{{ player.grandTotal }}</td>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import sortMixin from '@/mixins/sort-mixin'

export default {
  name: 'match-results-player',
  props: ['report', 'round'],
  mixins: [sortMixin],
  mounted () {
    if (this.sortField !== 'grandTotal') {
      this.sortBy('grandTotal')
    }
  },
  data () {
    return {
      onlyIndividuals: false
    }
  },
  computed: {
    individualPlayers () {
      return this.report.players.filter(p => p.isIndividual)
    },
    visiblePlayers () {
      const players = this.report.players
        .filter(p => p.round === this.round || (!this.round && p.round === 1))
        .map(p => ({ ...p, totals: this.playerScores(p), grandTotal: this.playerTotal(p) }))
      players.sort((a, b) => this.sortZeroLast(a, b, 'grandTotal'))
      let lastScore = 0
      let lastPlace = 0
      players.forEach((p, idx) => {
        if (p.grandTotal === lastScore) {
          p.place = lastPlace + '-T'
          players[idx - 1].place = p.place
        } else {
          p.place = idx + 1
        }
        lastPlace = parseInt(p.place)
        lastScore = p.grandTotal
      })
      return players
    },
    playerTotal () {
      return player => this.playerScores(player).find(s => s.dropped)
        ? 0
        : this.playerScores(player).map(s => s.total).reduce((a, b) => a + b)
    },
    playerScores () {
      return player => {
        return this.report.players // individualPlayers
          .filter(p => p.playerId === player.playerId)
          .sort((a, b) => a.round - b.round)
          .map(p => ({ round: p.round, total: p.total, dropped: p.dropped, counted: p.counted }))
      }
    }
  },
  watch: {
    round () {
      const field = this.round ? 'total' : 'grandTotal'
      if (this.sortField !== field) {
        this.sortBy(field)
      }
    }
  }
}
</script>

<style>

</style>
