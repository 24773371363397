<template>
  <div class="container my-3">
    <div v-if="!report">Loading</div>

    <div v-else class="text-left">
      <div class="row">
        <div class="col-sm-12 col-md-7">
          <h5>
            <button class="btn btn-outline-secondary mx-3" @click="goBack">
              <font-awesome-icon icon="fa-caret-left"></font-awesome-icon>
            </button>
            <span v-if="mode === 0">
              {{ report.regionName.toUpperCase() }}
              {{ report.divisionName.toUpperCase() }}
              MEGA MATCH RESULTS
            </span>
            <span v-else>
              {{ report.divisionName.toUpperCase() }}
              TOURNAMENT RESULTS
            </span>
          </h5>
        </div>

        <div class="col-sm-12 col-md-5 text-right small text-muted">
          <router-link
            class="text-dark"
            :to="{ path: `/report/${eventId}/1` }"
            :class="[view === 1 && 'active']">
            view 1
          </router-link>
          &nbsp;
          <router-link
          class="text-dark"
            :to="{ path: `/report/${eventId}/2` }"
            :class="[view === 2 && 'active']">
            view 2
          </router-link>
          &nbsp;
          <button v-if="accessLevel"
            class="btn btn-outline-secondary"
            :class="showChooseRecipients && 'active'"
            @click="chooseRecipients">Send Results</button>

          <button class="btn icon" @click="copyToClipboard" title="Copy window URL to clipboard.">
            <font-awesome-icon icon="far fa-copy"></font-awesome-icon>
          </button>
        </div>

        <div v-if="showChooseRecipients" class="col-12">
          <choose-recipients
            :report="report"
            @cancel="showChooseRecipients = false"
            @select="sendLink"></choose-recipients>
        </div>
      </div>

      <div class="row my-3">
        <div class="col-12">
          <div class="float-left mx-3">
            <table>
              <tr v-if="mode === 1"><td>Tournament:</td><td><b>{{ report.eventName }}</b></td></tr>
              <tr><td>Date:</td><td><b>{{ report.eventDate | longDate }}</b></td></tr>
              <tr><td>Location:</td><td><b>{{ report.courseName }}</b></td></tr>
              <tr v-if="mode === 0"><td>Hosted by:</td><td><b>{{ report.hostTeamName }}</b></td></tr>
            </table>
          </div>
          <div class="float-left mx-5">
            <table>
              <tr><td>Teams:</td><td><b>{{ report.teams.length }}</b></td></tr>
              <tr><td>Golfers:</td><td><b>{{ report.players.length }}</b></td></tr>
            </table>
          </div>
          <router-link
          v-if="report.regionType === 0"
            class="btn btn-outline-primary mx-4"
            :to="{ path: `/report/averages/${report.regionId}` }">
            Player Averages
          </router-link>
          <div class="float-right mt-4">
            <label v-if="report.rounds > 1" class="mx-4">Round:</label>
            <div v-if="report.rounds > 1" class="btn-group">
              <button v-for="round in report.rounds" :key="'round' + round"
                class="btn btn-outline-primary"
                :class="[selectedRound === round && 'active']"
                @click="selectedRound = round">
                {{ round }}
              </button>
              <button
                class="btn btn-outline-primary"
                :class="[selectedRound === 0 && 'active']"
                @click="selectedRound = 0">
                Total
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref="reportData" :key="1">
        <div v-if="currentView === 1" class="row">
          <div class="col-sm-12 col-md-6">
            <match-results-team class="mb-3" :round="selectedRound" :report="report"></match-results-team>
            <team-standings v-if="mode === 0"
              class="my-3" :totalEvents="report.numEvents" :standings="report.teamStandings"></team-standings>
          </div>
          <div class="col-sm-12 col-md-6">
            <match-results-player :round="selectedRound" :report="report"></match-results-player>
          </div>
        </div>

        <div v-if="currentView === 2" class="row">
          <div class="col-sm-12 mb-3 col-md-6">
            <match-results-list :round="selectedRound" :report="report"></match-results-list>
          </div>
          <div class="col-sm-12 col-md-6">
            <team-standings v-if="mode === 0"
              :totalEvents="report.numEvents" :standings="report.teamStandings"></team-standings>
            <match-results-player v-else :round="selectedRound" :report="report"></match-results-player>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import MatchResultsTeam from '@/components/MatchResultsTeam'
import MatchResultsPlayer from '@/components/MatchResultsPlayer'
import MatchResultsList from '@/components/MatchResultsList'
import TeamStandings from '@/components/TeamStandings'
import ChooseRecipients from '@/components/ChooseRecipients'

export default {
  name: 'report-view',
  props: ['eventId', 'view'],
  components: { MatchResultsTeam, MatchResultsPlayer, MatchResultsList, TeamStandings, ChooseRecipients },
  data () {
    return {
      report: null,
      defaultView: 1,
      defaultColumns: parseInt(sessionStorage.getItem('reportColumns') || 1),
      recipients: [],
      showChooseRecipients: false,
      selectedRound: 1
    }
  },
  mounted () {
    this.getMatchResults()
  },
  computed: {
    ...mapState(['user']),
    mode () {
      return this.report.eventName ? 1 : 0
    },
    currentView () { return +this.view || this.defaultView },
    accessLevel () {
      return this.user && this.user.id && this.user.accessLevel
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'home' })
    },
    getMatchResults () {
      if (this.eventId) {
        return axios.post('/api/report.php', { action: 'getMatchResults', eventId: this.eventId })
          .then(response => {
            if (response.success && response.report) {
              response.report.players.sort((a, b) => a.total - b.total)
              this.report = response.report
              if (this.report.rounds > 1) {
                this.selectedRound = 0 // switch to "Total" view.
              }
            }
          })
      }
    },
    copyToClipboard () {
      navigator.clipboard.writeText(window.location.href)
      alert('URL copied to clipboard')
    },
    chooseRecipients () {
      if (!this.accessLevel) { return }
      this.showChooseRecipients = !this.showChooseRecipients
    },
    sendLink (recipients) {
      this.showChooseRecipients = false
      axios.post('/api/index.php', {
        action: 'sendLink',
        eventId: this.eventId,
        recipients: recipients.map(r => r.id),
        url: window.location.href
      })
    }
  }
}
</script>

<style scoped>
a { text-decoration: none; }
a.active, .router-link-active { font-weight: bold; }
.one-column { width: 600px; }
</style>
