<template>
  <div>
    <div class="p-2 report-heading">
      <h5>Regional Standings after {{ numEvents }} of {{ totalEvents }} matches.</h5>
    </div>
    <table class="w-100">
      <tr class="heading">
        <th @click="sortBy('place')">Place <sort-icon :sortObj="sortObj" field="place"></sort-icon></th>
        <th @click="sortBy('teamName')">Team <sort-icon :sortObj="sortObj" field="teamName"></sort-icon></th>
        <th class="text-right" @click="sortBy('points')">Points <sort-icon :sortObj="sortObj" field="points"></sort-icon></th>
      </tr>
      <tr v-for="row in sortedData(standings)" :key="'standings-row-' + row.teamId">
        <td>{{ row.place }}{{ isTied(row.place) ? '-T' : '' }}</td>
        <td>{{ row.teamName }}</td>
        <td class="text-right">{{ row.points }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import sortMixin from '@/mixins/sort-mixin'

export default {
  name: 'team-standings',
  props: ['standings', 'totalEvents'],
  mixins: [sortMixin],
  computed: {
    numEvents () {
      return Math.max(...this.standings.map(s => s.numEvents))
    },
    isTied () {
      return place => this.standings.filter(s => s.place === place).length > 1
    }
  }
}
</script>

<style>

</style>
