export const getSessionUser = () => {
  const u = sessionStorage.getItem('d10_user')
  return u ? JSON.parse(u) : null
}

export const setSessionUser = user => {
  if (user) {
    sessionStorage.setItem('d10_user', JSON.stringify(user))
  } else {
    sessionStorage.removeItem('d10_user')
  }
}
