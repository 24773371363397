import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getSessionUser, setSessionUser } from '@/utils/session-utils'

Vue.use(Vuex)

const emptyState = () => {
  const sorts = localStorage.getItem('sorts')
    ? JSON.parse(localStorage.getItem('sorts'))
    : []
  return {
    siteName: 'District 10 Golf',
    user: getSessionUser(),
    regions: [],
    teams: [],
    users: [],
    accessLevels: [],
    selectedRegion: localStorage.getItem('selectedRegion') ? JSON.parse(localStorage.getItem('selectedRegion')) : null,
    selectedEvent: null,
    divisions: [],
    courses: [],
    events: [],
    sorts,
    selectedTeam: null
  }
}

export default new Vuex.Store({
  state: emptyState(),
  getters: {
    eventCourse (state) {
      if (state.courses.length) {
        return state.courses.find(c => state.selectedEvent && c.id === state.selectedEvent.courseId)
      } else { return null }
      /* else {
        dispatch('getCourses')
          .then(() => {
            return state.courses.find(c => state.selectedEvent && c.id === state.selectedEvent.courseId)
          })
      }
      */
    },
    sortedRegions (state) {
      const r = state.regions.slice(0)
      r.sort((a, b) => {
        const n1 = a.name + a.divisionName
        const n2 = b.name + b.divisionName
        return n1 > n2
          ? 1
          : n1 < n2
            ? -1
            : 0
      })
      return r
    }
  },
  mutations: {
    SET_USER (state, user) {
      setSessionUser(user)
      state.user = user
    },
    ADD_REGION_ACCESS (state, regionId) {
      state.user.access.regions.push(regionId)
    },
    SET_REGIONS (state, regions) {
      state.regions.splice(0)
      if (regions.length) {
        state.regions.push(...regions)
      }
    },
    SET_TEAMS (state, teams) {
      state.teams = teams || []
    },
    SET_SELECTED_TEAM (state, team) {
      state.selectedTeam = team
    },
    SET_SELECTED_REGION (state, regionId) {
      state.selectedRegion = state.regions.find(r => r.id === regionId) || null
      localStorage.setItem('selectedRegion', JSON.stringify(state.selectedRegion))
    },
    SET_EVENTS (state, events) {
      state.events.splice(0)
      if (events.length) {
        state.events.push(...events)
      }
    },
    SET_COURSES (state, courses) {
      state.courses.splice(0)
      state.courses.push(...courses)
    },
    SET_DIVISIONS (state, divisions) {
      state.divisions.splice(0)
      state.divisions.push(...divisions)
    },
    SET_SORT (state, sortObj) {
      const obj = state.sorts.find(s => s.componentName === sortObj.componentName)
      if (obj) {
        obj.field = sortObj.field
        obj.direction = sortObj.direction
      } else {
        state.sorts.push(sortObj)
      }
      localStorage.setItem('sorts', JSON.stringify(state.sorts))
    },
    SET_SELECTED_EVENT (state, event) {
      state.selectedEvent = state.events.indexOf(event) >= 0 ? event : null
    },
    CLOSE_SELECTED_EVENT (state) {
      state.selectedEvent.open = 0
    }
  },
  actions: {
    logout ({ commit }) {
      commit('SET_USER', null)
    },
    setSelectedTeam ({ commit }, team) {
      commit('SET_SELECTED_TEAM', team)
    },
    setSelectedRegion ({ commit, dispatch }, regionId) {
      commit('SET_SELECTED_REGION', regionId)
      dispatch('setSelectedEvent', null)
      return dispatch('initRegion', regionId)
    },
    setSelectedEvent ({ commit }, event) {
      commit('SET_SELECTED_EVENT', event)
    },
    async login ({ commit }, credentials) {
      const response = await axios.post('/api/index.php?action=login', credentials)
      if (response.success === true && response.user) {
        commit('SET_USER', response.user)
      }
      return response
    },
    setSort ({ commit }, sortObj) {
      commit('SET_SORT', sortObj)
    },
    async initRegion ({ state, dispatch }, regionId) {
      if (!state.regions.length) { await dispatch('getRegions') }
      if (!state.courses.length) { dispatch('getCourses') }
      if (!state.divisions.length) { dispatch('getDivisions') }

      await dispatch('getAllEvents', regionId)
      dispatch('setSelectedEvent', state.events[0])
      if (state.selectedRegion && state.selectedRegion.regionType < 99) {
        await dispatch('getTeams', regionId)
      } else {
        await dispatch('getTeams', null)
      }
    },
    submitScores ({ commit, state }, round) {
      if (!state.selectedEvent) { return }
      return axios.post('/api/index.php', { action: 'submitScores', eventId: state.selectedEvent.id, round })
        .then(response => {
          if (response.success && response.closed) {
            commit('CLOSE_SELECTED_EVENT', state.selectedEvent.id)
          }
          return response
        })
    },
    getDivisions ({ commit }) {
      axios.get('/api/index.php?action=getDivisions')
        .then(response => {
          if (response.success && response.divisions) {
            commit('SET_DIVISIONS', response.divisions)
          }
        })
    },
    getCourses ({ commit }) {
      return axios.get('/api/index.php?action=getCourses')
        .then(response => {
          if (response.success && response.courses) {
            commit('SET_COURSES', response.courses)
          }
        })
    },
    saveCourse ({ dispatch }, course) {
      return axios.post('/api/index.php', { action: 'saveCourse', course })
        .then(response => {
          if (response.success) {
            dispatch('getCourses')
          }
          return response
        })
    },
    deleteCourse ({ dispatch }, courseId) {
      return axios.post('/api/index.php', { action: 'deleteCourse', courseId })
        .then(response => {
          if (response.success) {
            dispatch('getCourses')
          }
        })
    },
    getEvents ({ commit }, regionId) {
      return axios.get('/api/index.php?action=getEvents&regionId=' + regionId || '')
        .then(response => {
          if (response.success) {
            commit('SET_EVENTS', response.events)
          }
        })
    },
    getAllEvents ({ commit }, regionId) { // include tournament events
      return axios.get('/api/index.php?action=getEvents')
        .then(response => {
          if (response.success) {
            commit('SET_EVENTS', response.events.filter(r => !regionId || r.regionId === regionId || r.regionType === 99))
          }
        })
    },
    saveEvent ({ dispatch, state, commit }, event) {
      if (event.eventDate && event.courseId) {
        return axios.post('/api/index.php', { action: 'saveEvent', ...event })
          .then(response => {
            if (response.success) {
              dispatch('getEvents', event.regionId)
                .then(response => {
                  if (state.selectedEvent && state.selectedEvent.id === event.id) {
                    commit('SET_SELECTED_EVENT', event)
                  }
                })
            }
            return response
          })
      }
    },
    getRegions ({ commit }) {
      return axios.get('/api/index.php?action=allRegions')
        .then(response => {
          commit('SET_REGIONS', response.regions || [])
        })
    },
    getTeams ({ commit }, regionId) {
      const url = '/api/index.php?action=getTeams&regionId=' + regionId
      return axios.get(url)
        .then(response => {
          commit('SET_TEAMS', response.teams || [])
        })
    },
    async saveRegion ({ commit, dispatch, state }, region) {
      if (typeof region === 'string') {
        region = { name: region }
      }
      const response = await axios.post('/api/index.php?action=saveRegion', region)
      if (response.success) {
        dispatch('getRegions')
      }
      return response
    },
    async saveTeam ({ dispatch }, { team, regionId }) {
      if (typeof team === 'string') { // name of new team being added
        team = { name: team, regionId }
      }
      if (!team.id && !regionId) {
        return Promise.reject(new Error('Missing region id.'))
      }
      const response = await axios.post('/api/index.php?action=saveTeam', team)
      if (response.success) {
        dispatch('getTeams', regionId)
      }
      return response
    },
    deleteRegion ({ commit, dispatch }, regionId) {
      return axios.post('/api/index.php?action=deleteRegion', { regionId })
        .then(response => {
          if (response.success) {
            dispatch('getRegions')
          }
        })
    },
    deleteTeam ({ dispatch }, { teamId, regionId }) {
      return axios.post('/api/index.php?action=deleteTeam', { teamId })
        .then(response => {
          if (response.success) {
            dispatch('getTeams', regionId)
          }
        })
    }
  },
  modules: {
  }
})
