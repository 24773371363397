<template>
  <div>
    <div class="report-heading">
      <h5>Team Results</h5>
    </div>
    <table class="w-100">
      <tr class="table-row heading">
        <th @click="sortBy('name')">
          Team <sort-icon :sortObj="sortObj" field="name"></sort-icon>
        </th>
        <th class="text-right" @click="sortBy(round ? 'total' : 'grandTotal')">
          Score <sort-icon :sortObj="sortObj" :field="round ? 'total' : 'grandTotal'"></sort-icon>
        </th>
        <th class="text-right" @click="sortBy('')">
          Place <sort-icon :sortObj="sortObj" field="place"></sort-icon>
        </th>
        <th v-if="report.regionType === 0" class="text-right" @click="sortBy('points')">
          Points <sort-icon :sortObj="sortObj" field="points"></sort-icon>
        </th>
      </tr>
      <tr v-for="(team) in sortedData(teams)" :key="'team-' + team.teamId">
        <td>{{ team.name }}</td>
        <td class="text-right">{{ round ? (team.total || 'NTS') : team.grandTotal }}</td>
        <td class="text-right">{{ place(team) }}</td>
        <td v-if="report.regionType === 0" class="text-right">{{ team.points }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import sortMixin from '@/mixins/sort-mixin'

export default {
  name: 'match-results-team',
  mixins: [sortMixin],
  props: ['report', 'round'],
  computed: {
    districtChampionship () {
      const lower = this.report.eventName.toLowerCase()
      return lower && lower.indexOf('district') > -1 && lower.indexOf('championship') > -1
    },
    rounds () {
      return this.districtChampionship ? 1 : this.report.rounds
    },
    place () {
      return team => this.teams.indexOf(team) + 1
    },
    teams () {
      const fld = this.round ? 'total' : 'grandTotal'
      return this.report.teams
        .filter(t => t.round === this.round || (!this.round && t.round === 1))
        .map(t => ({ ...t, grandTotal: this.teamTotal(t.id) }))
        .sort((a, b) => a[fld] && !b[fld]
          ? -1
          : !a[fld] && b[fld]
              ? 1
              : a[fld] - b[fld])
    },
    teamTotal () {
      return teamId =>
        this.report.teams
          .filter(t => t.teamId === teamId && t.round <= this.rounds)
          .map(t => t.total)
          .reduce((a, b) => a + b)
    }
  }
}
</script>

<style>

</style>
