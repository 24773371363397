<template>
  <div class="user-bit">
    <a v-if="user"
      href="javascript:;"
      @click="setShow('user')">{{ user.name }}
    </a>
    <a v-else-if="$route.name !== 'login'"
      href="javascript:;"
      @click="setShow('login')">Sign In
    </a>

    <div v-if="show === 'user'"
      class="user-bit-panel">
      <div style="text-align:right">
        <font-awesome-icon icon="fa-times"></font-awesome-icon>
      </div>
      <div class="text-left my-2">
        {{ user.name }}<br>
        {{ user.email }}<br>
      </div>
      <div class="text-right my-2">
        <button class="btn btn-outline-secondary" @click="logout">Sign Out</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'user-bit',
  props: {
    user: Object
  },
  data () {
    return {
      show: '',
      loginEmail: '',
      loginPass: ''
    }
  },
  methods: {
    clear () {
      this.loginEmail = this.loginPass = this.show = ''
    },
    login () {
      // alert('Dude just tried to log in, lol')
      if (this.loginEmail && this.loginPass) {
        this.$store.dispatch('login', { user: this.loginEmail, pass: this.loginPass })
        this.clear()
      }
    },
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          if (this.$route.name !== 'login') {
            this.$router.push({ name: 'login' })
          }
        })
      this.clear()
    },
    hideUserPanel () {
      this.show = ''
    },
    setShow (value = '') {
      if (value === 'login') {
        this.$router.push({ name: 'login' })
        return
      }
      this.show = this.show === value ? '' : value
      if (value === 'user') {
        window.removeEventListener('click', this.hideUserPanel)
        setTimeout(() => { window.addEventListener('click', this.hideUserPanel) }, 500)
      } else {
        window.removeEventListener('click', this.hideUserPanel)
      }
      // this.show = !value || this.show === value ? '' : value
    }
  }
}
</script>

<style scoped>
.user-bit { text-align: right; background-color: inherit; }
.user-bit-panel {
    position: absolute;
    right: 10px;
    margin-top: 10px;
    text-align: left;
    padding: 10px 25px;
    border: 1px solid lightgrey;
    background-color: #fff;
  }
</style>
