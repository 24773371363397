import { mapState, mapActions } from 'vuex'
import SortIcon from '@/components/SortIcon'
import { sortZeroLast } from '@/utils/sort-utils'

export default {
  components: { SortIcon },
  computed: {
    ...mapState(['sorts']),
    componentName () {
      return this.$options.name
    },
    sortObj () {
      return this.sorts.find(s => s.componentName === this.componentName) || {}
    },
    sortField () {
      return this.sortObj && this.sortObj.field
    },
    sortField2 () {
      return this.sortObj && this.sortObj.field2
    },
    sortDirection () {
      return (this.sortObj && this.sortObj.direction) || 1
    },
    sortedData () {
      return data => {
        const ret = data.slice(0)
        if (['total', 'grandTotal'].includes(this.sortField)) {
          ret.sort((a, b) => this.sortZeroLast(a, b, this.sortField, this.sortField2, this.sortDirection))
          return ret
        }
        ret.sort((a, b) => a[this.sortField] < b[this.sortField]
          ? -this.sortDirection
          : a[this.sortField] > b[this.sortField]
            ? this.sortDirection
            : !this.sortField2
                ? 0
                : a[this.sortField2] < b[this.sortField2]
                  ? -this.sortDirection
                  : a[this.sortField2] > b[this.sortField2]
                    ? this.sortDirection
                    : 0)
        return ret
      }
    }
  },
  methods: {
    ...mapActions(['setSort']),
    sortBy (field, field2) {
      let direction = this.sortDirection || 1
      if (field === this.sortField) {
        direction = -direction
      }
      this.setSort({ componentName: this.componentName, field, field2, direction })
    },
    sortZeroLast
  }
}
