<template>
  <div class="text-right">
    <div class="d-inline-block w-50 m-3 p-3 editor-panel">
      <multi-select
        class="d-inline-block w-100 my-3"
        :options="recipients"
        :close-on-select="false"
        :custom-label="obj => obj.organization + ' - ' + (obj.name || obj.email)"
        :show-labels="false"
        track-by="id"
        v-model="selectedRecipients"
        :multiple="true"
        placeholder="select recipients"
      ></multi-select>
      <div class="my-3">
        <button
          :disabled="!selectedRecipients.length"
          class="btn btn-primary"
          @click="$emit('select', selectedRecipients)">
          Send To Selected People
        </button>
        <button class="btn btn-outline-secondary" @click="$emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'choose-recipients',
  props: ['report'],
  data () {
    return {
      recipients: [],
      selectedRecipients: []
    }
  },
  mounted () {
    if (!this.user || !this.user.accessLevel) {
      return
    }
    this.getRecipients()
  },
  computed: {
    ...mapState(['user', 'selectedRegion', 'siteName']),
    subject () {
      return `${this.eventDate} ${this.siteName} ${this.selectedRegion.name} ${this.selectedRegion.divisionName} Results`
    },
    eventDate () {
      return moment(this.report.eventDate).format('MMMM DD')
    }
  },
  methods: {
    getRecipients () {
      return axios.post('/api/index.php', { action: 'getRecipients' })
        .then(response => {
          if (response.success && response.recipients) {
            this.recipients = response.recipients.filter(r => r.active && (!r.regionId || r.regionId === this.selectedRegion.id))
          }
        })
    }
  }
}
</script>

<style>

</style>
