<template>
  <div>
    <table class="w-100 mb-3">
      <tr>
        <td class="text-center">
          <img alt="D10 GOLF logo" height="125" src="../assets/logo.png">
        </td>
        <td>
          <div class="fs-3 my-3">Welcome to District 10 Golf</div>

          <div class="my-4">
            Region
            <multi-select
              class="d-inline-block mx-3" style="width: 300px;"
              :options="useRegions"
              :searchable="false"
              v-model="selectedRegion"
              label="name"
              :custom-label="obj => obj.name + ' ' + obj.divisionName"
              track-by="id"
              :show-labels="false"
              :allow-empty="false"
              placeholder="All Regions"
            ></multi-select>
          </div>
          <div>
            <button v-if="selectedRegion && selectedRegion.regionType === 0"
              class="btn btn-outline-primary mx-3 float-right"
              @click="goPlayerAverages"
            >
              Player Averages
            </button>

            Team Name
            <input type="text" class="form-control mx-3 d-inline-block" style="width: 10em;" v-model="searchString">

            <div class="btn-group mx-3">
              <button class="btn btn-outline-secondary"
                :class="[divisionId === 0 && 'active']"
                @click="divisionId = 0">Boys</button>
              <button class="btn btn-outline-secondary"
                :class="[divisionId === 1 && 'active']"
                @click="divisionId = 1">Girls</button>
              <button class="btn btn-outline-secondary"
                :class="[divisionId === 2 && 'active']"
                @click="divisionId = 2">All</button>
            </div>

            <div style="max-width: 600px; margin: auto;">
              <button v-for="team in teamRegions" :key="'teamRegion' + team.id"
                class="btn mx-1 my-2"
                :class="[selectedRegion && selectedRegion.id === team.regionId ? 'btn-primary' : 'btn-outline-primary']"
                @click="setRegionByTeam(team)">
                {{ team.name }} {{ team.divisionName }}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </table>
      <!--tr class="editor-panel border-0">
        <th>Date</th>
        <th>Name / Host</th>
        <th>Course</th>
        <th v-if="!selectedRegion">Region</th>
        <th></th>
      </tr-->

      <template v-for="useView in ['upcoming', 'completed']">
        <div :key="'sectionlabel' + useView" class="table-row fs-4 px-2 my-2">
          <b>{{ useView | titleCase }} Events</b>
          <div v-if="useView === 'upcoming'" class="fw-normal d-inline-block small mx-4">
            Weeks ahead:
            <div class="btn-group">
              <button v-for="week in 4" :key="'week' + week"
                class="btn btn-outline-secondary my-2"
                :class="[weeksAhead === week && 'active']" @click="weeksAhead = week">{{ week }}</button>
            </div>
          </div>
        </div>
        <div :key="'noresults' + useView" class="p-4"
          v-if="!(useView === 'upcoming' ? currentEvents : completedEvents).length">
          There are no {{ useView }} events.
        </div>
        <table :key="'results' + useView" class="w-100">
          <tr v-for="event in useView === 'upcoming' ? currentEvents : completedEvents" :key="'event' + event.id"
            :class="[resultsAccessible(event) ? 'clickable' : 'not-clickable']"
            @click="goMatchResults(event)"
            :title="event.open ? 'This match is not complete.' : 'Click to view match results.'">
            <td>
              <font-awesome-icon :icon="event.open ? 'fa-times' : 'fa-check'"
                :class="[!resultsAccessible(event) ? 'text-danger' : 'text-success']"></font-awesome-icon>
              {{ event.eventDate | date }}
            </td>
            <td>{{ event.name || 'Mega Match' }} {{ event.hostTeamName ? '@' : '' }} {{ event.hostTeamName }}</td>
            <td>{{ event.courseName }}</td>
            <td v-if="!selectedRegion">{{ event.regionName }} {{ eventRegion(event.regionId).divisionName }}</td>
            <td>
              <button v-if="event.eventInfo" class="float-right btn icon"
                @click.stop="showInfo(event)">
                <font-awesome-icon icon="fa fa-info-circle"></font-awesome-icon>
              </button>
            </td>
          </tr>
        </table>
      </template>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
// import sortMixin from '@/mixins/sort-mixin'

export default {
  name: 'home-content',
  props: {
    msg: String
  },
  // mixins: [sortMixin],
  data () {
    return {
      searchString: '',
      divisionId: 2,
      weeksAhead: 1
    }
  },
  mounted () {
    if (!this.regions.length) {
      this.getTeams()
      this.getRegions()
        .then(() => {
          if (!this.selectedRegion && this.regions.length) {
            this.setSelectedRegion(this.regions[0].id)
          }
        })
    } else {
      this.getEvents(this.selectedRegion && this.selectedRegion.id)
    }
  },
  computed: {
    ...mapState(['regions', 'events', 'teams']),
    useRegions () {
      return [
        { id: 0, name: 'All', divisionName: 'Regions' },
        ...this.regions.filter(r => r.active && r.regionType !== 1 && (r.divisionId === this.divisionId || this.divisionId === 2))
      ]
    },
    visibleEvents () {
      return this.events.filter(e => (!this.selectedRegion || this.selectedRegion.id === e.regionId) &&
        (this.divisionId === 2 || this.divisionId === this.regionDivisionId(e.regionId)))
    },
    currentEvents () {
      return this.visibleEvents
        .filter(e => (moment(e.eventDate).isSameOrAfter(moment().startOf('day')) &&
          moment(e.eventDate).isBefore(moment(new Date().getTime()).add(this.weeksAhead, 'week'))) && e.open)
        .sort((a, b) => {
          return a.eventDate < b.eventDate
            ? -1
            : a.eventDate > b.eventDate
              ? 1
              : 0
        })
    },
    completedEvents () {
      return this.visibleEvents
        .filter(e => moment(e.eventDate).isBefore(moment(new Date().getTime() + 86400000)) &&
          moment(e.eventDate).isAfter(moment(new Date().getTime()).subtract(16, 'week')) && !e.open)
        .sort((a, b) => {
          return a.eventDate < b.eventDate
            ? 1
            : a.eventDate > b.eventDate
              ? -1
              : 0
        })
    },
    regionDivisionId () {
      return regionId => this.regions.find(r => r.id === regionId).divisionId
    },
    teamRegions () {
      return this.teams
        .filter(t => this.searchString &&
          (this.divisionId === 2 || this.regionDivisionId(t.regionId) === this.divisionId) &&
          t.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1)
        .map(t => ({ ...t, divisionName: this.regions.find(r => r.id === t.regionId).divisionId === 1 ? 'Girls' : 'Boys' }))
    },
    selectedRegion: {
      get () { return this.$store.state.selectedRegion },
      set (value) {
        this.$store.dispatch('setSelectedRegion', (value && value.id) || null)
      }
    },
    eventRegion () {
      return regionId => this.regions.find(r => r.id === regionId) || {}
    },
    resultsAccessible () {
      return event => !event.open || (event.rounds > 1 && event.numTeamScores > 0)
    }
  },
  methods: {
    ...mapActions(['getEvents', 'getRegions', 'setSelectedRegion', 'getTeams']),
    onSearchChange (searchString) {
      this.searchString = searchString
    },
    goMatchResults (event) {
      if (!this.resultsAccessible(event)) {
        return
      }
      this.$router.push({ path: `/report/${event.id}/2` })
    },
    goPlayerAverages () {
      if (this.selectedRegion) {
        this.$router.push({ path: `/report/averages/${this.selectedRegion.id}` })
      }
    },
    setRegionByTeam (team) {
      this.searchString = team.name
      this.setSelectedRegion(team.regionId)
    },
    showInfo (event) {
      this.$router.push({ path: '/info/' + event.id })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.event-row.clickable:hover { background-color: #aca; }
.event-row.not-clickable:hover { background-color: lightgrey; color: gray; }
</style>
