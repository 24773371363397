<template>
  <div class="container-fluid nav-bar mb-2">
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <nav>
          <router-link to="/home">
            <font-awesome-icon icon="fa-solid fa-house" size="lg" class="home-icon">
            </font-awesome-icon>Home</router-link>
          <router-link to="/about">About</router-link>

          <span v-if="user && user.accessLevel >= 3">
            <router-link to="/team" title="Manage players and event participation">Team</router-link>
          </span>

          <span v-if="user && user.accessLevel >= 5">
            <router-link to="/scoring" title="Enter and manage match scoring">Scoring</router-link>
          </span>

          <span v-if="user && user.accessLevel >= 10">
            <router-link to="/admin" title="Manage regions, teams and users">Admin</router-link>
          </span>
        </nav>
      </div>
      <div class="col-sm-4 col-xs-12 text-right">
        <nav>
          <user-bit :user="user"></user-bit>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import UserBit from '@/components/UserBit'

export default {
  name: 'nav-bar',
  components: { UserBit },
  computed: {
    user () { return this.$store.state.user }
  }
}
</script>

<style>

.nav-bar { background-color: #b5cfbc; border-bottom: 0px solid gray; }

.home-icon { margin: 0 10px 0 0;}
nav {
  color: black;
  text-align: left;
}

nav a {
  display: inline-block;
  padding: 6px 12px;
  color: #000;
  text-decoration: none;
}

nav a:hover { background-color: #f5fcf666!important; }

nav a.router-link-active {
  font-weight: bold;
  background-color: #f5fcf6;
}
</style>
