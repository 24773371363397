<template>
  <div>
    <player-averages
      :regionId="regionId"
      :aClass="aClass"
      :divisionId="divisionId"
      :players="players"
      :events="events"></player-averages>
  </div>
</template>

<script>
import axios from 'axios'
import PlayerAverages from '@/components/PlayerAverages'

export default {
  name: 'averages-view',
  components: { PlayerAverages },
  props: ['regionId', 'aClass', 'divisionId'],
  data () {
    return {
      events: [],
      players: []
    }
  },
  mounted () {
    this.getPlayerAverages()
  },
  watch: {
    regionId () {
      this.getPlayerAverages()
    }
  },
  methods: {
    getPlayerAverages () {
      axios.post('/api/report.php', {
        action: 'getPlayerAverages',
        regionId: this.regionId,
        aClass: this.aClass,
        divisionId: this.divisionId,
        year: new Date().getFullYear()
      })
        .then(response => {
          if (response.success) {
            this.events.splice(0)
            this.events.push(...response.events)
            this.players.splice(0)
            this.players.push(...response.players)
          }
        })
    }
  }
}
</script>

<style>

</style>
