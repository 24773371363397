<template>
  <font-awesome-icon v-if="sortObj && sortObj.field === field" :icon="iconClass"></font-awesome-icon>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'sort-icon',
  props: ['sortObj', 'field'],
  computed: {
    ...mapState(['sorts']),
    iconClass () {
      return this.sortObj && this.sortObj.direction === 1 ? 'fa-caret-down' : 'fa-caret-up'
    }
  }
}
</script>

<style>

</style>
