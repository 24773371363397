import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import Multiselect from 'vue-multiselect'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/site.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faBowlingBall, faHouse, faTimes, faUsers, faFaceSmile, faPeopleGroup, faRecycle, faArrowRotateRight, faAnglesRight, faClipboardList,
  faEdit, faTrashCan, faBolt, faSave, faSquareCheck, faSquareMinus, faSquarePlus, faPlay, faDollarSign, faCircleHalfStroke, faQrcode,
  faCaretUp, faCaretDown, faCaretLeft, faCaretRight, faArrowRight, faSpinner, faSkullCrossbones, faCheck, faCheckCircle, faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare, faCopy // , faSquareCheck
} from '@fortawesome/free-regular-svg-icons'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('multi-select', Multiselect)
Vue.use(DatePicker)
// Vue.use(ToggleButton)
Vue.filter('date', value => moment(value).format('MMM DD YYYY'))
Vue.filter('longDate', value => moment(value).format('dddd MMM Do YYYY'))
// TODO: Make this multi-word
Vue.filter('titleCase', value => value[0].toUpperCase() + value.substring(1).toLowerCase())

/* add icons to the library */
library.add(faHouse, faBowlingBall, faTimes, faUsers, faFaceSmile, faPeopleGroup, faRecycle, faArrowRotateRight, faAnglesRight, faClipboardList,
  faCopy, faInfoCircle,
  faEdit, faTrashCan, faBolt, faSave, faSquare, faSquareCheck, faSquareMinus, faSquarePlus, faPlay, faDollarSign, faCircleHalfStroke, faQrcode,
  faCaretUp, faCaretDown, faCaretLeft, faCaretRight, faArrowRight, faSpinner, faSkullCrossbones, faCheck, faCheckCircle)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
