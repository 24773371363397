<template>
  <div class="container text-center">
    <home-content msg="Welcome to District 10 Golf"></home-content>
    <div class="my-4 text-right small text-muted">v. {{ version }}</div>
  </div>
</template>

<script>
import HomeContent from '@/components/HomeContent'

export default {
  name: 'HomeView',
  components: {
    HomeContent
  },
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  }
}
</script>
