<template>
  <div id="app">
    <div v-if="loading"><h1>Loading, one moment...</h1></div>
    <div v-else>
      <nav-bar style="position: relative;z-index: 10;"></nav-bar>
      <router-view style="position: relative; z-index: 5;"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import NavBar from '@/components/NavBar'

export default {
  components: { NavBar },
  data () {
    return {
      loading: false
    }
  },
  created () {
    axios.interceptors.response.use(response => {
      const resp = response.data || {}
      // if (window.location.hostname === 'localhost') {
      //   console.log(resp)
      // }
      if (this.user &&
          this.user.id &&
          !resp.userId
      ) {
        this.logout()
        if (this.$route.meta.accessLevel > 0) {
          alert('Your session expired. Please log in again.')
          this.$router.push({ name: 'login' })
          return { success: false }
        } else {
          return resp
        }
      } else {
        return resp
      }
    })
  },
  mounted () {
    if (!this.regions.length) {
      this.loading = true
      this.getRegions()
        .then(() => {
          const regionId = (this.user && this.user.regionId) || (this.regions.length && this.regions[0].id)
          if (regionId && !this.selectedRegion) {
            this.setSelectedRegion(regionId)
              .then(() => {
                this.loading = false
              })
          } else {
            this.loading = false
          }
        })
    }
  },
  computed: {
    ...mapState(['user', 'regions', 'selectedRegion'])
  },
  methods: {
    ...mapActions(['setSelectedRegion', 'getRegions', 'logout'])
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
