<template>
  <div class="container">
    <div class="row">
      <div class="my-2">
        Email: <input type="text" v-model="credentials.user" @keypress.enter="doLogin" autocomplete="loginfield"><br>
      </div>
      <div class="my-2">
        Password: <input type="password" v-model="credentials.pass" @keypress.enter="doLogin" autocomplete="passwordfield"><br>
      </div>
      <div class="my-2">
        <button class="btn btn-primary" @click="doLogin">Sign In</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'login-view',
  data () {
    return {
      credentials: {
        user: '',
        pass: ''
      },
      initialRoutes: [
        { accessLevel: 3, name: 'team' },
        { accessLevel: 5, name: 'scoring' },
        { accessLevel: 10, path: '/admin/matches' }
      ]
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapActions(['login']),
    doLogin () {
      if (this.credentials.user && this.credentials.pass) {
        this.login(this.credentials)
          .then(response => {
            if (response.success) {
              this.credentials.user = ''
              this.credentials.pass = ''
              const route = this.initialRoutes.find(r => r.accessLevel === response.user.accessLevel)
              this.$router.push(route || { name: 'home' })
            }
          })
      }
    }
  }
}
</script>

<style>

</style>
